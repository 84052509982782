import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Banner from "../containers/Banner"
import BlogPostsList from "../containers/BlogPostsList"
import Breadcrumbs from "../containers/Breadcrumbs"
import Consultation from "../containers/Consultation"
import Layout from "../containers/Layout"
import NewEbook from "../containers/NewEbook"
import Newsletter from "../containers/Newsletter"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/alpacked.style"

const BlogPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query Blog {
      prismicBlog {
        data {
          seo_description {
            text
          }
          seo_short_description {
            text
          }
          seo_title {
            text
          }
          image_link_preview {
            fluid {
              src
            }
          }
          title {
            html
          }
          subtitle
          image {
            url
          }
        }
      }
      prismicBlogPost(data: { featured_article: { eq: true } }) {
        first_publication_date(formatString: "MMM DD, YYYY")
        uid
        tags
        data {
          intro_text
          publication_date(formatString: "MMM DD, YYYY")
          category
          author {
            slug
            document {
              ... on PrismicAuthor {
                id
                data {
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1400, quality: 72) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  name {
                    text
                  }
                  occupation {
                    text
                  }
                }
              }
            }
          }
          title {
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1400, quality: 72) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      allPrismicBlogPost(
        sort: { fields: first_publication_date, order: DESC }
      ) {
        edges {
          node {
            uid
            id
            tags
            first_publication_date(formatString: "MMM DD, YYYY")
            data {
              publication_date(formatString: "MMM DD, YYYY")
              category
              featured_article
              intro_text
              title {
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              author {
                slug
                document {
                  ... on PrismicAuthor {
                    id
                    data {
                      name {
                        text
                      }
                      occupation {
                        text
                      }
                      image {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 1400, quality: 72) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const {
    title,
    image,
    subtitle,
    seo_description,
    seo_title,
    seo_short_description,
    image_link_preview,
  } = data.prismicBlog.data

  const { edges } = data.allPrismicBlogPost
  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname="/blog">
        <ContentWrapper>
          <Banner title={title.html} subtitle={subtitle} image={image.url} />
          <Newsletter pathname="/blog" />
          <Breadcrumbs page="Blog" authors />
          {/* <FeauturedArticle content={featuredContent} /> */}
          <NewEbook />
          <BlogPostsList content={edges} />
          <Consultation pathname="/blog" />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default BlogPage
